import "bootstrap/dist/css/bootstrap.css";

import "./nav.css";
import $ from 'jquery'
import { useState, useEffect } from "react";
import { Link, useParams} from "react-router-dom";
import img from "../images/prudential.png";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { TiMessages } from "react-icons/ti";
import { CiCreditCard1 } from "react-icons/ci";
import { MdOutlineChevronRight } from "react-icons/md";
import { IoChevronDownSharp } from "react-icons/io5";
import { IoPerson } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import { width } from "@fortawesome/free-solid-svg-icons/fa0";
import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });



export default function NavBar(props) {

  const params = useParams()



  const [family , setFamily] = useState("")
  const [education, setEducation] = useState("")
  const [life , setLife] = useState("")
  const [medical , setMedical]= useState("")
  const [corporate, setCorporate] = useState("")

  let educationArray =[]
  let lifeArray =[]
  let medicalArray =[]
  let familyArray = []
  let corporateArray = []
  let cats = [3,4,5,6]
  let y
  let x



  function closeNav(){

    $(window).on('click', function async (e){ 
      
      let clickedId = $(this).attr('id')
      if($(e.target).hasClass('nv-btn')){
       $(e.target).removeClass('nv-btn')
       $(e.target).addClass('nv-btn1')
      }    
   
      
        $.when( $(e.target).addClass('navBtn')).then(close()) 
      
    })


    function close(){

      $(window).on('click', function(e){ 
        let clickedId = $(this).attr('id')
        let newId = clickedId + "-pane"
        if($('.tab-dtl').hasClass('show')){
        $(e.target).removeClass('active')
        $(e.target).removeClass('navBtn')
       
        $(e.target).removeClass('nv-btn1')
        $(e.target).addClass('nv-btn')
       
        //alert('#' + newId)
                        $('.tab-dtl').removeClass('show')
        }
      
      })
    
    }
  
  }

  


  window.onscroll = () => { 
    let outerNav = document.getElementById('outerNav')
    let innerNav = document.getElementById('innerNav')
    let inner = document.getElementById('myTab')
    let innerTextColor = document.getElementById('link1')
   // let lifeColor = document.getElementById('life-tab')
    let policyColor = document.getElementById('policy-tab')
    let claimsColor = document.getElementById('claims-tab')
    let adviseColor = document.getElementById('advise-tab')
    let aboutColor = document.getElementById('about-tab')
    let readersColor = document.getElementById('readers-tab')
    let tabSection = document.getElementById('myTabContent')


    if(window.scrollY > 2){
     

       $(outerNav).addClass('scrolled')
       $(inner).addClass('fixed-top')
       inner.style.width = "100%"
       inner.style.borderRadius = "0"
       inner.style.backgroundColor = "white"
       innerTextColor.style.color = "black"
      // lifeColor.style.color = "black"
       policyColor.style.color = "black"
       claimsColor.style.color = "black"
       adviseColor.style.color = "black"
       aboutColor.style.color = "black"
       readersColor.style.color = "black"
       tabSection.style.top = "0%"
     //  $(inner).addClass('noMargin')
       $(inner).removeClass('mt-3')
       $(innerNav).removeClass('hgh-nav')
     }else if(window.scrollY < 1){
    
      $(outerNav).removeClass('scrolled')
      $(innerNav).addClass('hgh-nav')
   //    $(inner).removeClass('noMargin')
   inner.style.width = "70%"
   inner.style.borderRadius = "5px"
   inner.style.backgroundColor = "#ed1b2e"
   innerTextColor.style.color = "white"
  // lifeColor.style.color = "white"
    policyColor.style.color = "white"
       claimsColor.style.color = "white"
       adviseColor.style.color = "white"
       aboutColor.style.color = "white"
       readersColor.style.color = "white"
       tabSection.style.top = "14%"
      $(inner).removeClass('fixed-top')
      $(inner).addClass('mt-3')

     }

  }


  useEffect( () => {


    if(props.data == 'corporate' || props.section == "corporate" ){
          //  alert('color')

      let individualButton = document.getElementById('indv')
       individualButton.style.backgroundColor = "#ececf1"
      let  corporateButton = document.getElementById('co-p').style.backgroundColor = "white"
      let  individualOptions = document.getElementById('individual').style.display = "none"
      let  individualMobOptions = document.getElementById('individualMob').style.display = "none"
      let  corporateMobOptions = document.getElementById('corporateMob').style.display = "block"
      let  corporateOptions = document.getElementById('corporate').style.display = "inline"
      
   
    
      }else{
        let individualButton = document.getElementById('indv')
       individualButton.style.backgroundColor = "white"
      let  corporateButton = document.getElementById('co-p').style.backgroundColor = "#ececf1"
      let  corporateOptions = document.getElementById('corporate').style.display = "none"
      let  individualOptions = document.getElementById('individual').style.display = "inline"
      let  individualMobOptions = document.getElementById('individualMob').style.display = "block"
      let  corporateMobOptions = document.getElementById('corporateMob').style.display = "nonce"

      }




    

      if(!params.corporate){
      
  client
  .query({
    query: gql`
    query($id: Int!){
     allPolicyPages(where: {categoryId:$id}) {
       edges {
         node {
           id
           policy {
             blackheadingtext
             productdescription
             productimage {
               mediaItemUrl
             }
           }
         }
       }
     }
  }
  `,
  variables: {id:3}
  })
  .then(async result => { 
   console.log(result)

    let medicalDetailPack
    

    
  

      if (medicalArray.length != result.data.allPolicyPages.edges.length){
        for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
    
           medicalDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext}]
           medicalArray.push(medicalDetailPack)
            
       }
     }
    
     setMedical(medicalArray)
  
    
 

  })



  client
  .query({
    query: gql`
    query($id: Int!){
     allPolicyPages(where: {categoryId:$id}) {
       edges {
         node {
           id
           policy {
             blackheadingtext
             productdescription
             productimage {
               mediaItemUrl
             }
           }
         }
       }
     }
  }
  `,
  variables: {id:4}
  })
  .then(async result => { 
   console.log(result)


    let lifeDetailPack
 
    

    let x 

    if (lifeArray.length != result.data.allPolicyPages.edges.length){
      for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
  
         lifeDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext}]
         lifeArray.push(lifeDetailPack)
          
     }
   }
  
   setLife(lifeArray)
// }   
  })

 


  client
  .query({
    query: gql`
    query($id: Int!){
     allPolicyPages(where: {categoryId:$id}) {
       edges {
         node {
           id
           policy {
             blackheadingtext
             productdescription
             productimage {
               mediaItemUrl
             }
           }
         }
       }
     }
  }
  `,
  variables: {id:5}
  })
  .then(async result => { 
   console.log(result)
   
    let familyDetailPack

if (familyArray.length != result.data.allPolicyPages.edges.length){
    for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 

       familyDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext}]
       familyArray.push(familyDetailPack)
        
   }
 }

 setFamily(familyArray)

  })


  client
  .query({
    query: gql`
    query($id: Int!){
     allPolicyPages(where: {categoryId:$id}) {
       edges {
         node {
           id
           policy {
             blackheadingtext
             productdescription
             productimage {
               mediaItemUrl
             }
           }
         }
       }
     }
  }
  `,
  variables: {id:6}
  })
  .then(async result => { 
   console.log(result)

   
    let educationDetailPack 



  if (educationArray.length != result.data.allPolicyPages.edges.length){
    for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
      
       educationDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext}]
       educationArray.push(educationDetailPack)
       console.log(y)
       console.log(education)
}

}

setEducation(educationArray)

  })

  }

if(params.corporate == 'corporate' || props.section == "corporate"){
//alert("corp")
  client
  .query({                
    query: gql`
    query($id: Int!){
     allPolicyPages(where: {categoryId:$id}) {
       edges {
         node {
           id
           policy {
             blackheadingtext
             productdescription
             productimage {
               mediaItemUrl
             }
           }
         }
       }
     }
  }
  `,
  variables: {id:12}
  })
  .then(async result => { 
   console.log(result)

   
    let corporateDetailPack 



  if (corporateArray.length != result.data.allPolicyPages.edges.length){
    for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
      
       corporateDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext}]
       corporateArray.push(corporateDetailPack)
       //console.log(y)
       console.log(corporate)
}

}

setCorporate(corporateArray)

  })

}

}, [])  

let corporateDetails
let corporateDetailsMob
if(corporate.length > 0){ 
  
  corporateDetails = corporate.map((corp, i) => 
    <div class="d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href={`/policy/corporate/${corp[0].id}`}>
                 {corp[0].heading} <MdOutlineChevronRight class="arr"/>
                </a>
              </div>
  
  
  )

  corporateDetailsMob = corporate.map((corp, i)=>
  
    <div class="mb-p d-flex justify-content-between align-items-center">
    <a href={`/policy/corporate/${corp[0].id}`} className="link"> {corp[0].heading} </a> 
     <MdOutlineChevronRight class="arr" />
   </div>

  )

}



  let medicalDetails

  if(medical.length > 0){ 
  
    medicalDetails = medical.map((medic, i) => 
      <a href={`/policy/individual/${medic[0].id}`} class="tb-lnk">{medic[0].heading}</a>
    
    
    )

  }

  let lifeDetails

  if(life.length > 0){ 
  
    lifeDetails = life.map((live, i) => 
      <a href={`/policy/individual/${live[0].id}`} class="tb-lnk">{live[0].heading}</a>
    
    
    )

  }

  let educationDetails

  if(education.length > 0){ 
  
    educationDetails = education.map((educate, i) => 
      <a href={`/policy/individual/${educate[0].id}`} class="tb-lnk">{educate[0].heading}</a>
    
    
    )

  }

  let familyDetails

  if(family.length > 0){ 
  
    familyDetails = family.map((fam, i) => 
      <a href={`/policy/individual/${fam[0].id}`} class="tb-lnk">{fam[0].heading}</a>
    
    
    )

  }


  return (
    <>
      <nav class="navbar navbar-expand-lg container-fluid nv-hd" id="outerNav">
        <div class="container" id="navCont">
          < a href="/" class="link">
          <img class="image" src={img} alt="" />
          </a>
         
          <div class="dissapear">
            <div class=" d-flex align-items-center">
              <div class="d-flex align-items-center ">
              <a id ="" className="link"  href="#" target="_blank">
                
                <div className="txt"> <BsTelephone className="crd" /> 02018887600</div>
                </a>
              <a id ="" className="link"  href="https://jcms.prudentialzenith.com/paystackui" target="_blank">
                
                <div className="txt"> <CiCreditCard1 className="crd" /> Online Payments</div>
                </a>
                <div class="d-flex " className="click">
                  <a id="indv" href="/">
                    Individual
                  </a>
                  <a id ="co-p"  href="/corporate/home">
                    Corporate
                  </a>
                </div>
                <a id =""  href="https://jcms.prudentialzenith.com/pzlcustomerportal" target="_blank">
                <button className="lgin"> Customer Portal </button>
                </a>
          
            
              </div>
            </div>
          </div>
          <div id="burgerBox">
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          </div>
        
        </div>
      </nav>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <img src={img} alt="" />
          </h5>
          <button
            type="button"
            class="btn-close redText"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <p class="gap-1">
               {/* 
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#life-stimulator"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-hd">Life Simulator</div>
        
            </div>
          
            <div class="collapse" id="lifestimulator">
              <div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                  <MdOutlineChevronRight class="arr" />
                </div>
              </div>
            </div>
  */}
            <hr></hr>
          </p>
          <p class=" gap-1">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#buyapolicy"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-hd">Products</div>
              <div>
                <IoChevronDownSharp class="arr" />
              </div>
            </div>
            <div class="collapse" id="buyapolicy">
              <div id="individualMob">
                <div class="mb-p d-flex justify-content-between align-items-center">
                 <a href="/policy/Medical/home" className="link"> Medical Insurance </a> 
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/policy/Life/home" className="link"> Life Insurance </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/policy/Family/home" className="link"> Family Insurance </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/policy/Education/home" className="link"> Educational Insurance </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
              </div>

              <div id="corporateMob">
          
                {corporateDetailsMob}
              
              
              </div>
            </div>
            <hr></hr>
          </p>
          <p class="gap-1">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#makeaclaim"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-hd">Make A Claim</div>
              <div>
                <IoChevronDownSharp class="arr" />
              </div>
            </div>
            <div class="collapse" id="makeaclaim">
              <div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/claims" className="link"> About Claims </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/claims/forms" className="link"> Claims Forms </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/claims/faqs" className="link"> Claims Faqs </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/claims/promise" className="link"> Claims Promise </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/claims/glossary" className="link"> Claims Glossary </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
              </div>
            </div>
            <hr></hr>
          </p>
          <p class="gap-1">
          
              <div class="mb-hd"> <a href="/contact" className="link"> Talk To An Advisor  </a> </div>
             
            
            <hr></hr>
          </p>
          <p class=" gap-1">


            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#aboutus"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-hd">About Us</div>
              <div>
                <IoChevronDownSharp class="arr" />
              </div>
            </div>
            <div class="collapse" id="aboutus">
              <div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/about" className="link">  About Us </a>
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                 <a href="/about/leadership" className="link"> Leadership </a> 
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/about/achievements" className="link"> Achievements </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/about/statements" className="link">  Financial Statements </a>
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/about/careers" className="link">  Corportate Careers </a>
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                  <a href="/news" className="link"> News Room </a>
                  <MdOutlineChevronRight class="arr" />
                </div>
              </div>
            </div>
            <hr></hr>
          </p>
          <p class=" gap-1">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#readersdigest"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-hd">Readers Digest</div>
              <div>
                <IoChevronDownSharp class="arr" />
              </div>
            </div>
            <div class="collapse" id="readersdigest">
              <div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/blog/lifeblog" className="link" > Life Insurance 101 </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/blog/healthblog" className="link" > Health and Wellness </a>  
                  <MdOutlineChevronRight class="arr" />
                </div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <a href="/blog/markets" className="link" >  Markets and Investments </a>
                  <MdOutlineChevronRight class="arr" />
                </div>
              </div>
            </div>
            <hr></hr>
          </p>
          <button
            type="button"
            class="mx-auto d-flex align-items-center btn  login-btn"
          >
            <IoPerson class="psn" />
           <a href="https://jcms.prudentialzenith.com/pzlcustomerportal/" target="_blank" className="link"> Customer Portal  </a> 
          </button>
          <button
            type="button"
            class="mx-auto d-flex align-items-center btn Online-btn"
          >
          <a href="https://jcms.prudentialzenith.com/paystackui/" target="_blank" className="link">  Online Payment </a>  
          </button>
          <button
            type="button"
            class="mx-auto d-flex align-items-center btn  Enter-btn"
          >
         <a href="/corporate/home" target="_blank" className="link"> Corporate </a>    
          </button>
        </div>
      </div>

      <div class="dissapear hgh-nav nav-style" id="innerNav">
        <ul
          class="mt-3 d-flex justify-content-center rounded-start rounded-end low-nav nav-tabs"
          id="myTab"
          role="tablist"
        >
          {/* 
          <li class="nav-item" role="presentation">
            <button
              class="nv-btn"
              id="life-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              Life Simulator
            </button>
          </li>

           */}

          <li class="nav-item" role="presentation">
            <button
              class="nv-btn"
              id="policy-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
              onClick={closeNav}
             
            >
              Products
            </button>
          </li>
          <li class="nav-item nv-btn" role="presentation">
            <button
              class=" nv-btn"
              id="claims-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact-tab-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"
              onClick={closeNav}
            
            >
              Claims & Services
            </button>
          </li>
          <li class="nav-item" role="presentation">
            
            <button
              class=" nv-btn"
              id="advise-tab"
              data-bs-toggle="tab"
              data-bs-target="#talk-to-an-advisor-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"

            
            >
              <a href="/contact" id="link1">
              Talk To An Advisor
              </a>
          
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class=" nv-btn"
              id="about-tab"
              data-bs-toggle="tab"
              data-bs-target="#About-us-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"
              onClick={closeNav}
           
            >
              About Us
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class=" nv-btn"
              id="readers-tab"
              data-bs-toggle="tab"
              data-bs-target="#Readers-digest-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"
              onClick={closeNav}
          
            >
              Readers digest
            </button>
          </li>
        </ul>

        <div class="tab-content tb-cnt fixed-top" id="myTabContent">
        
          <div
            class="tab-pane tab-dtl nav-pad fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <div id="individual">
            <div class="  d-flex justify-content-around">
              <div class=" d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/policy/Medical/home">
                  Medical Insurance <MdOutlineChevronRight class="arr" />
                </a>

                {medicalDetails}
              </div>
              <div class="d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/policy/Life/home">
                  Life Insurance <MdOutlineChevronRight class="arr" />
                </a>

                {lifeDetails}
              </div>
              <div class="  d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/policy/Family/home">
                  Family insurance <MdOutlineChevronRight class="arr" />
                </a>

               {familyDetails}
              </div>
              <div class="d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/policy/Education/home">
                  Educational Insurance <MdOutlineChevronRight class="arr" />
                </a>

               {educationDetails}
              </div>
            </div>
            </div>

            <div id="corporate"> 
            <div className="  d-flex justify-content-around">
            {corporateDetails}
            </div>
        
            
            </div>
      
          </div>
          <div
            class="tab-pane nav-pad tab-dtl fade "
            id="contact-tab-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabindex="0"
          >
            <div class="  d-a text-center d-flex flex-column align-items-start">
              <a href="/claims" class="hd-lnk">
                Claims Services <MdOutlineChevronRight class="arr" />
              </a>
              <a href="/claims/promise" class="tb-lnk">Claims Promise</a>
              <a href="/claims/forms" class="tb-lnk">Claims Form</a>
              <a href="/claims/faqs" class="tb-lnk">Claims FAQ</a>
              <a href="/claims/glossary" class="tb-lnk">Claims Glossary</a>
              
            </div>
          </div>
          <div
            class="tab-pane tab-dtl fade "
            id="talk-to-an-advisor-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabindex="0"

          >
            <div class="  d-a text-center d-flex flex-column align-items-start">
              <a href="/contact" class="hd-lnk">
                Talk to an Advisor <MdOutlineChevronRight class="arr" />
              </a>
            </div>
          </div>
          <div
            class="tab-pane nav-pad tab-dtl fade "
            id="About-us-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabindex="0"
          >
            <div class="d-flex ">
              <div class=" d-a text-center d-flex flex-column align-items-start">
                <a href="/about" class="hd-lnk" >
                  About Us <MdOutlineChevronRight class="arr" />
                </a>

                <a href="/about/leadership" class="tb-lnk" >
                  Leadership
                </a>
                <a href="/about/statements" class="tb-lnk" >
                  Financial Statements
                </a>
                <a href="/about/achievements" class="tb-lnk" >
                  Achievements
                </a>
                <a href="/about/careers" class="tb-lnk" >
                  Corporate Careers
                </a>
             
              </div>
            
              <div class="d-a ps-5 text-center d-flex flex-column align-items-start">
                <a href="/news" class="hd-lnk">
                  News room <MdOutlineChevronRight class="arr" />
                </a>

                <a href="/news/press-release" class="tb-lnk"> Press release</a>
              </div>
            </div>
          </div>
          <div
            class="tab-pane nav-pad tab-dtl fade"
            id="Readers-digest-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <div class="  d-flex justify-content-around">
              <div class=" d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/blog/lifeblog">
                  Life Insurance 101 <MdOutlineChevronRight class="arr" />
                </a>
              </div>
              <div class="d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/blog/healthblog">
                  Health & Wellness <MdOutlineChevronRight class="arr" />
                </a>
              </div>
              <div class="  d-a text-center d-flex flex-column align-items-start">
                <a class="hd-lnk" href="/blog/markets">
                  Markets and Investments <MdOutlineChevronRight class="arr" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}